const abiList = require.context('./abis/', false, /\.(js)$/i)
const abis = {}

abiList.keys().map(fileName => {
	const name = fileName.match(/\w+/)[0]
	const abi = require(`./abis/${name}.js`).default
	abis[name] = abi
	return abis
})

const getContract = async (web3, abiName, contractAddr = null) => {
	const abiObject = abis[abiName]
	const contract = contractAddr || abiObject.contract
	if (!contract) {
		throw new Error(`${abiName} Missing contract`)
	}
	return new web3.eth.Contract(abiObject.abi, contract)
}

export { abis, getContract }
