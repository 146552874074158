import './polyfill'
import React from 'react'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-notifications/lib/notifications.css'
import './assets/css/style.css'
import './assets/css/new.css'
import { RecoilRoot } from 'recoil'
import App from './App'
//import reportWebVitals from './reportWebVitals'
//import * as serviceWorker from './serviceWorker'

const root = createRoot(document.getElementById('root'))

root.render(
	<RecoilRoot>
		<App />
	</RecoilRoot>
)

//reportWebVitals()

//serviceWorker.register()
