import { contracts } from '../../data/contracts'

const abi = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'previousAdmin',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'newAdmin',
				type: 'address'
			}
		],
		name: 'AdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'beacon',
				type: 'address'
			}
		],
		name: 'BeaconUpgraded',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'implementation',
				type: 'address'
			}
		],
		name: 'Upgraded',
		type: 'event'
	},
	{
		stateMutability: 'payable',
		type: 'fallback'
	},
	{
		inputs: [],
		name: 'admin',
		outputs: [
			{
				internalType: 'address',
				name: 'admin_',
				type: 'address'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newAdmin',
				type: 'address'
			}
		],
		name: 'changeAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'implementation',
		outputs: [
			{
				internalType: 'address',
				name: 'implementation_',
				type: 'address'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newImplementation',
				type: 'address'
			}
		],
		name: 'upgradeTo',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newImplementation',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'upgradeToAndCall',
		outputs: [],
		stateMutability: 'payable',
		type: 'function'
	},
	{
		stateMutability: 'payable',
		type: 'receive'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'seller',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'packageId',
				type: 'uint256'
			}
		],
		name: 'AskCancel',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'seller',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'packageId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'tokenIds',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'amounts',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'askPrice',
				type: 'uint256'
			}
		],
		name: 'AskNew',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'seller',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'packageId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'askPrice',
				type: 'uint256'
			}
		],
		name: 'AskUpdate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			}
		],
		name: 'CollectionClose',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'creator',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tradingFee',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'creatorFee',
				type: 'uint256'
			}
		],
		name: 'CollectionNew',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'creator',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tradingFee',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'creatorFee',
				type: 'uint256'
			}
		],
		name: 'CollectionUpdate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'admin',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'treasury',
				type: 'address'
			}
		],
		name: 'NewAdminAndTreasuryAddresses',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'minimumAskPrice',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'maximumAskPrice',
				type: 'uint256'
			}
		],
		name: 'NewMinimumAndMaximumAskPrices',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'claimer',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'RevenueClaim',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'token',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'TokenRecovery',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'packageId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'seller',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'buyer',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'tokenIds',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'amounts',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'askPrice',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'netPrice',
				type: 'uint256'
			}
		],
		name: 'Trade',
		type: 'event'
	},
	{
		inputs: [],
		name: 'TOTAL_MAX_FEE',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_creator',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tradingFee',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_creatorFee',
				type: 'uint256'
			}
		],
		name: 'addCollection',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'adminAddress',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_packageId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_price',
				type: 'uint256'
			}
		],
		name: 'buyPackageUsingOLAND',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'price',
				type: 'uint256'
			}
		],
		name: 'calculatePriceAndFeesForCollection',
		outputs: [
			{
				internalType: 'uint256',
				name: 'netPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'tradingFee',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'creatorFee',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_packageId',
				type: 'uint256'
			}
		],
		name: 'cancelAskOrder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'claimPendingRevenue',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			}
		],
		name: 'closeCollectionForTradingAndListing',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: '_tokenIds',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: '_amounts',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256',
				name: '_askPrice',
				type: 'uint256'
			}
		],
		name: 'createAskOrder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_adminAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_treasuryAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_tokenAddress',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_minimumAskPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_maximumAskPrice',
				type: 'uint256'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'maximumAskPrice',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'minimumAskPrice',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_packageId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_newPrice',
				type: 'uint256'
			}
		],
		name: 'modifyAskOrder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_collection',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_creator',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_tradingFee',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_creatorFee',
				type: 'uint256'
			}
		],
		name: 'modifyCollection',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			},
			{
				internalType: 'bytes',
				name: '',
				type: 'bytes'
			}
		],
		name: 'onERC1155BatchReceived',
		outputs: [
			{
				internalType: 'bytes4',
				name: '',
				type: 'bytes4'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			},
			{
				internalType: 'bytes',
				name: '',
				type: 'bytes'
			}
		],
		name: 'onERC1155Received',
		outputs: [
			{
				internalType: 'bytes4',
				name: '',
				type: 'bytes4'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'pendingRevenue',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_token',
				type: 'address'
			}
		],
		name: 'recoverFungibleTokens',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_adminAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_treasuryAddress',
				type: 'address'
			}
		],
		name: 'setAdminAndTreasuryAddresses',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes4',
				name: 'interfaceId',
				type: 'bytes4'
			}
		],
		name: 'supportsInterface',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'token',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'treasuryAddress',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_minimumAskPrice',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_maximumAskPrice',
				type: 'uint256'
			}
		],
		name: 'updateMinimumAndMaximumPrices',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'cursor',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'size',
				type: 'uint256'
			}
		],
		name: 'viewAsksByCollection',
		outputs: [
			{
				internalType: 'uint256[]',
				name: 'packageIds',
				type: 'uint256[]'
			},
			{
				components: [
					{
						internalType: 'address',
						name: 'seller',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256[]',
						name: 'tokenIds',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'amounts',
						type: 'uint256[]'
					}
				],
				internalType: 'struct OceanlandNFTMarket.Ask[]',
				name: 'askInfo',
				type: 'tuple[]'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: 'packageIds',
				type: 'uint256[]'
			}
		],
		name: 'viewAsksByCollectionAndPackageIds',
		outputs: [
			{
				internalType: 'bool[]',
				name: 'statuses',
				type: 'bool[]'
			},
			{
				components: [
					{
						internalType: 'address',
						name: 'seller',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256[]',
						name: 'tokenIds',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'amounts',
						type: 'uint256[]'
					}
				],
				internalType: 'struct OceanlandNFTMarket.Ask[]',
				name: 'askInfo',
				type: 'tuple[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'collection',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'seller',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'cursor',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'size',
				type: 'uint256'
			}
		],
		name: 'viewAsksByCollectionAndSeller',
		outputs: [
			{
				internalType: 'uint256[]',
				name: 'packageIds',
				type: 'uint256[]'
			},
			{
				components: [
					{
						internalType: 'address',
						name: 'seller',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256[]',
						name: 'tokenIds',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'amounts',
						type: 'uint256[]'
					}
				],
				internalType: 'struct OceanlandNFTMarket.Ask[]',
				name: 'askInfo',
				type: 'tuple[]'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'cursor',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'size',
				type: 'uint256'
			}
		],
		name: 'viewCollections',
		outputs: [
			{
				internalType: 'address[]',
				name: 'collectionAddresses',
				type: 'address[]'
			},
			{
				components: [
					{
						internalType: 'enum OceanlandNFTMarket.CollectionStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'address',
						name: 'creatorAddress',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'tradingFee',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'creatorFee',
						type: 'uint256'
					}
				],
				internalType: 'struct OceanlandNFTMarket.Collection[]',
				name: 'collectionDetails',
				type: 'tuple[]'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_logic',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'admin_',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: '_data',
				type: 'bytes'
			}
		],
		stateMutability: 'payable',
		type: 'constructor'
	}
]

const contract = contracts[process.env.REACT_APP_NETWORK].NFT_Market

export default {
	abi,
	contract
}
