import React from 'react'
import { Route, Navigate } from 'react-router-dom'

export default function PrivateRoute({ path, wallet, children, guard }) {
	if (guard) {
		if (wallet) {
			return children
		} else {
			return <Navigate to="/Home" />
		}
	} else {
		return children
	}
}
