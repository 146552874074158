import React from 'react'

const Home = React.lazy(() => import('./containers/home'))
const Explore = React.lazy(() => import('./containers/explore'))
const Swap = React.lazy(() => import('./containers/swap'))
const Pool = React.lazy(() => import('./containers/pool'))
const Farm = React.lazy(() => import('./containers/farm'))
const NFT = React.lazy(() => import('./containers/nft'))
const Inventory = React.lazy(() => import('./containers/inventory'))
const PackageSale = React.lazy(() => import('./containers/package-sale'))
const BuyBooster = React.lazy(() => import('./containers/buy-booster'))
const Stake = React.lazy(() => import('./containers/stake'))

const routes = [
	{ path: '/', component: Home, guard: false },
	{ path: '/Home', component: Home, guard: false },
	{ path: '/Marketplace', component: Explore, guard: false },
	{ path: '/Swap', component: Swap, guard: false },
	{ path: '/Pool', component: Pool, guard: false },
	{ path: '/Farm', component: Farm, guard: false },
	{ path: '/NFT/:id', component: NFT, guard: false },
	{ path: '/Inventory', component: Inventory, guard: false },
	{ path: '/PackageSale', component: Home, guard: false },
	{ path: '/BuyBooster', component: BuyBooster, guard: false },
	{ path: '/Stake', component: Stake, guard: false }
]

export default routes.map(route => {
	return {
		...route,
		Component: route.component
	}
})
