import axios from 'axios'

const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL, headers: { 'Content-type': 'application/x-www-form-urlencoded' } })

instance.interceptors.request.use(
	config => {
		return config
	},
	error => {
		//console.log(error)
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	response => {
		return response
	},
	error => {
		//console.log(error)
		return Promise.reject(error)
	}
)

export default instance
