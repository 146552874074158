import nfts from '../data/nfts.json'
import http from '../helpers/http'

export async function getAskPackage(params, header) {
	const response = await http.get(`/api/askpackage${params && '?' + params}`, header && { headers: { header } })
	return {
		success: true,
		data: response.data
	}
}

export async function getHistory() {
	const response = await http.get(`/api/askpackage?${query}`)
	return {
		success: true,
		data: response.data.data
	}
}

export async function getNFTS() {
	return {
		success: true,
		data: nfts
	}
}

export async function getNFT(id) {
	const response = await http.get(`/api/nfts/${id}`)
	return {
		success: true,
		data: response.data
	}
}

export async function getNFTList(params) {
	const response = await http.get(`/api/nfts${params ? params : ''}`)
	return {
		success: true,
		data: response.data
	}
}
