import { contracts } from '../../data/contracts'

const abi = [
	{
		inputs: [
			{
				internalType: 'address',
				name: '_olandContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_nftContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_waterContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_foodContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_woodContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_metalContract',
				type: 'address'
			}
		],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'nftId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			}
		],
		name: 'NftCrafted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256'
			}
		],
		name: 'NftEquipped',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'voucherId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256'
			}
		],
		name: 'NftUnequipped',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'voucherId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'waterAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'foodAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'woodAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'metalAmount',
				type: 'uint256'
			}
		],
		name: 'ResourcesDeposited',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'voucherId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'waterAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'foodAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'woodAmount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'metalAmount',
				type: 'uint256'
			}
		],
		name: 'ResourcesWithdrawn',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'previousAdminRole',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'newAdminRole',
				type: 'bytes32'
			}
		],
		name: 'RoleAdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleGranted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleRevoked',
		type: 'event'
	},
	{
		inputs: [],
		name: 'DEFAULT_ADMIN_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'MINTER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'tokenId',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'nftId',
						type: 'uint256'
					},
					{
						internalType: 'uint256[]',
						name: 'burnNftIds',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256',
						name: 'olandAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'expireTime',
						type: 'uint256'
					},
					{
						internalType: 'bytes',
						name: 'signature',
						type: 'bytes'
					}
				],
				internalType: 'struct OceanLandGame.CraftInfo',
				name: 'craftInfo',
				type: 'tuple'
			}
		],
		name: 'craft',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'voucherId',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'waterAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'foodAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'woodAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'metalAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'expireTime',
						type: 'uint256'
					},
					{
						internalType: 'bytes',
						name: 'signature',
						type: 'bytes'
					}
				],
				internalType: 'struct OceanLandGame.DepositInfo',
				name: 'depositInfo',
				type: 'tuple'
			}
		],
		name: 'deposit',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'voucherId',
						type: 'uint256'
					},
					{
						internalType: 'address',
						name: 'from',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'tokenId',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'expireTime',
						type: 'uint256'
					},
					{
						internalType: 'bytes',
						name: 'signature',
						type: 'bytes'
					}
				],
				internalType: 'struct OceanLandGame.EquipInfo',
				name: 'equipInfo',
				type: 'tuple'
			}
		],
		name: 'equip',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getChainID',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			}
		],
		name: 'getRoleAdmin',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'grantRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'hasRole',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			},
			{
				internalType: 'bytes',
				name: '',
				type: 'bytes'
			}
		],
		name: 'onERC721Received',
		outputs: [
			{
				internalType: 'bytes4',
				name: '',
				type: 'bytes4'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'renounceRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'revokeRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes4',
				name: 'interfaceId',
				type: 'bytes4'
			}
		],
		name: 'supportsInterface',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'voucherId',
						type: 'uint256'
					},
					{
						internalType: 'address',
						name: 'to',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'tokenId',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'expireTime',
						type: 'uint256'
					},
					{
						internalType: 'bytes',
						name: 'signature',
						type: 'bytes'
					}
				],
				internalType: 'struct OceanLandGame.UnequipInfo',
				name: 'unequipInfo',
				type: 'tuple'
			}
		],
		name: 'unequip',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'voucherId',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'waterAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'foodAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'woodAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'metalAmount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'expireTime',
						type: 'uint256'
					},
					{
						internalType: 'bytes',
						name: 'signature',
						type: 'bytes'
					}
				],
				internalType: 'struct OceanLandGame.WithdrawInfo',
				name: 'withdrawInfo',
				type: 'tuple'
			}
		],
		name: 'withdraw',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	}
]

const contract = contracts[process.env.REACT_APP_NETWORK].OceanlandGame

export default {
	abi,
	contract
}
