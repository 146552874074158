/* eslint-disable */

const tokenFiles = require.context('./tokens/', false, /\.(js)$/i)
const tokens = {}

tokenFiles.keys().map(fileName => {
	fileName = fileName.toLowerCase()
	const name = fileName.match(/\w+/)[0]
	const token = require(`./tokens/${name}.js`).default
	token.balance = null
	tokens[name.toUpperCase()] = token
})

const farmFiles = require.context('./farms/', false, /\.(js)$/i)
const farms = {}

farmFiles.keys().map(fileName => {
	fileName = fileName.toLowerCase()
	const name = fileName.match(/\w+/)[0]
	const farm = require(`./farms/${name}.js`).default
	farms[name.toUpperCase()] = farm
})

const poolFiles = require.context('./pools/', false, /\.(js)$/i)
const pools = {}

poolFiles.keys().map(fileName => {
	fileName = fileName.toLowerCase()
	const name = fileName.match(/\w+/)[0]
	const pool = require(`./pools/${name}.js`).default
	pools[name.toUpperCase()] = pool
})

const stakeFiles = require.context('./stakes/', false, /\.(js)$/i)
const stakes = {}

stakeFiles.keys().map(fileName => {
	fileName = fileName.toLowerCase()
	const name = fileName.match(/\w+/)[0]
	const stake = require(`./stakes/${name}.js`).default
	stakes[name.toUpperCase()] = stake
})

export default {
	tokens,
	farms,
	pools,
	stakes
}
