import { contracts } from '../../data/contracts'

export default {
	id: 0,
	name: 'OLAND - BELLA',
	deposit: '0',
	pair: {
		token0: {
			name: 'OLAND',
			symbol: 'OLAND',
			address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
			logo: 'tokens/Oland.png'
		},
		token1: {
			name: 'BELLA',
			symbol: 'BELLA',
			address: contracts[process.env.REACT_APP_NETWORK].bellaAddres,
			logo: 'tokens/Bella.png'
		},
		address: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAN_BELLA
	}
}
