import { contracts } from '../../data/contracts'

export default {
	id: 2,
	name: 'OLAND - METAL',
	deposit: '0',
	pair: {
		token0: {
			name: 'OLAND',
			symbol: 'OLAND',
			address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
			logo: 'tokens/Oland.png'
		},
		token1: {
			name: 'METAL',
			symbol: 'OMETAL',
			address: contracts[process.env.REACT_APP_NETWORK].metalAddress,
			logo: 'tokens/OMetal.png'
		},
		address: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_Metal
	}
}
