import React, { useEffect, Suspense } from 'react'
import { useRecoilState } from 'recoil'
import { settingsState } from './atoms/settings'
import { walletState } from './atoms/wallet'
import { getSettings } from './api/settings'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Preload from './components/preload'
import Modal from './components/modal'
import routes from './routes'
import PrivateRoute from './private-route'
import { NotificationContainer } from 'react-notifications'
import { initWeb3 } from './helpers/web3'

export default function App() {
	const [settings, setSettings] = useRecoilState(settingsState)

	const [wallet, setWallet] = useRecoilState(walletState)

	// TEST SATIRI

	useEffect(() => {
		const fetchData = async () => {
			const response = await getSettings()
			setSettings(response.data)
		}
		fetchData()
		if (wallet) {
			initWeb3(settings, setSettings, wallet, setWallet)
		}
	}, [setSettings, setWallet])

	return (
		<Router>
			<Suspense fallback={<Preload />}>
				<Routes>
					{routes.map(({ path, Component, guard }, index) => (
						<Route
							key={`route_${index}`}
							path={path}
							element={
								<PrivateRoute path={path} wallet={wallet} guard={guard}>
									<Component />
								</PrivateRoute>
							}
						/>
					))}
				</Routes>
				{settings.selectedNFT && <Modal type={settings.selectedNFT.type} nft={settings.selectedNFT.data} text={settings.selectedNFT.text} wallet={wallet} setWallet={setWallet} page={settings.selectedNFT.page} />}
				<NotificationContainer />
			</Suspense>
		</Router>
	)
}
