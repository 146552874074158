import { contracts } from '../../data/contracts'

export default {
	name: 'OLAND - TFBX',
	apy: '111',
	deposit: '0',
	token0: {
		name: 'OLAND',
		address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
		logo: 'tokens/Oland.png'
	},
	token1: {
		name: 'TFBX',
		address: contracts[process.env.REACT_APP_NETWORK].tfbxAddress,
		logo: 'tokens/TFBX.png'
	},
	farm: contracts[process.env.REACT_APP_NETWORK].olandTfbxFarm,
	pair: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_TFBX
}
