import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const settingsState = atom({
	key: 'settingsState',
	default: {},
	effects_UNSTABLE: [persistAtom]
})
