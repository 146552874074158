const CELO_PARAMS = {
	chainId: '0xa4ec',
	chainName: 'Celo',
	nativeCurrency: { name: 'Celo', symbol: 'Celo', decimals: 18 },
	rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com'],
	iconUrls: ['future']
}

const ALFAJORES_PARAMS = {
	chainId: '0xaef3',
	chainName: 'Celo',
	nativeCurrency: { name: 'Celo Testnet', symbol: 'Celo', decimals: 18 },
	rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
	blockExplorerUrls: ['https://testnet.bscscan.com'],
	iconUrls: ['future']
}

// BSC Mainnet Deployed Contracts (For Test)
const BNB_PARAMS = {
	chainId: '0x38',
	chainName: 'Smart BNB',
	nativeCurrency: { name: 'Smart BNB', symbol: 'BNB', decimals: 18 },
	rpcUrls: ['https://bsc-dataseed.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com'],
	iconUrls: ['future']
}

// BSC Testnet Deployed Contracts
const BNB_TEST_PARAMS = {
	chainId: '0x61',
	chainName: 'Smart Chain - Testnet',
	nativeCurrency: { name: 'BNB Testnet', symbol: 'BNB', decimals: 18 },
	rpcUrls: ['https://data-seed-prebsc-1-s3.binance.org:8545'],
	blockExplorerUrls: ['https://testnet.bscscan.com'],
	iconUrls: ['future']
}

// BSC Mainnet
const MAIN = {
	chainId: '0x38',
	chainName: 'Smart BNB',
	nativeCurrency: { name: 'Smart BNB', symbol: 'BNB', decimals: 18 },
	rpcUrls: ['https://bsc-dataseed.binance.org/'],
	blockExplorerUrls: ['https://bscscan.com'],
	iconUrls: ['future']
}

export { CELO_PARAMS, ALFAJORES_PARAMS, BNB_PARAMS, BNB_TEST_PARAMS, MAIN }
