import { contracts } from '../../data/contracts'

export default {
	id: 4,
	name: 'OLAND - WOOD',
	deposit: '0',
	pair: {
		token0: {
			name: 'OLAND',
			symbol: 'OLAND',
			address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
			logo: 'tokens/Oland.png'
		},
		token1: {
			name: 'WOOD',
			symbol: 'OWOOD',
			address: contracts[process.env.REACT_APP_NETWORK].woodAddress,
			logo: 'tokens/OWood.png'
		},
		address: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_Wood
	}
}
