import { contracts } from '../../data/contracts'

export default {
	id: 0,
	name: 'OLAND - TFBX',
	deposit: '0',
	pair: {
		token0: {
			name: 'OLAND',
			symbol: 'OLAND',
			address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
			logo: 'tokens/Oland.png'
		},
		token1: {
			name: 'TFBX',
			symbol: 'TFBX',
			address: contracts[process.env.REACT_APP_NETWORK].tfbxAddress,
			logo: 'tokens/TFBX.png'
		},
		address: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_TFBX
	}
}
