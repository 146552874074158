import { contracts } from '../../data/contracts'

export default {
	name: 'OLAND - BELLA',
	apy: '11',
	deposit: '0',
	token0: {
		name: 'OLAND',
		address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
		logo: 'tokens/Oland.png'
	},
	token1: {
		name: 'BELLA',
		address: contracts[process.env.REACT_APP_NETWORK].bellaAddres,
		logo: 'tokens/Bella.png'
	},
	farm: contracts[process.env.REACT_APP_NETWORK].olandBellaFarm,
	pair: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAN_BELLA
}
