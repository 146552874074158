import { contracts } from '../../data/contracts'

export default {
	name: 'OLAND - ARPA',
	apy: '11',
	deposit: '0',
	token0: {
		name: 'OLAND',
		address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
		logo: 'tokens/Oland.png'
	},
	token1: {
		name: 'ARPA',
		address: contracts[process.env.REACT_APP_NETWORK].arpaAddress,
		logo: 'tokens/Arpa.png'
	},
	farm: contracts[process.env.REACT_APP_NETWORK].olandArpaFarm,
	pair: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_ARPA
}
