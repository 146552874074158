import { contracts } from '../../data/contracts'

export default {
	id: 0,
	name: 'OLAND - ARPA',
	deposit: '0',
	pair: {
		token0: {
			name: 'OLAND',
			symbol: 'OLAND',
			address: contracts[process.env.REACT_APP_NETWORK].olandAddress,
			logo: 'tokens/Oland.png'
		},
		token1: {
			name: 'ARPA',
			symbol: 'ARPA',
			address: contracts[process.env.REACT_APP_NETWORK].arpaAddress,
			logo: 'tokens/Arpa.png'
		},
		address: contracts[process.env.REACT_APP_NETWORK].DEX.Pairs.OLAND_ARPA
	}
}
