import { contracts } from '../../data/contracts'

const abi = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'previousAdmin',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'newAdmin',
				type: 'address'
			}
		],
		name: 'AdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'beacon',
				type: 'address'
			}
		],
		name: 'BeaconUpgraded',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'implementation',
				type: 'address'
			}
		],
		name: 'Upgraded',
		type: 'event'
	},
	{
		stateMutability: 'payable',
		type: 'fallback'
	},
	{
		inputs: [],
		name: 'admin',
		outputs: [
			{
				internalType: 'address',
				name: 'admin_',
				type: 'address'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newAdmin',
				type: 'address'
			}
		],
		name: 'changeAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'implementation',
		outputs: [
			{
				internalType: 'address',
				name: 'implementation_',
				type: 'address'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newImplementation',
				type: 'address'
			}
		],
		name: 'upgradeTo',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newImplementation',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'upgradeToAndCall',
		outputs: [],
		stateMutability: 'payable',
		type: 'function'
	},
	{
		stateMutability: 'payable',
		type: 'receive'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'approved',
				type: 'bool'
			}
		],
		name: 'ApprovalForAll',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'nftIds',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'amounts',
				type: 'uint256[]'
			}
		],
		name: 'BoxOpened',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				indexed: false,
				internalType: 'struct OceanlandNFTBox.Category',
				name: 'category',
				type: 'tuple'
			}
		],
		name: 'CategoryAdded',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				indexed: false,
				internalType: 'struct OceanlandNFTBox.Category',
				name: 'category',
				type: 'tuple'
			}
		],
		name: 'CategoryUpdated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'Paused',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'previousAdminRole',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'newAdminRole',
				type: 'bytes32'
			}
		],
		name: 'RoleAdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleGranted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleRevoked',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]'
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'values',
				type: 'uint256[]'
			}
		],
		name: 'TransferBatch',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256'
			}
		],
		name: 'TransferSingle',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'string',
				name: 'value',
				type: 'string'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			}
		],
		name: 'URI',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'Unpaused',
		type: 'event'
	},
	{
		inputs: [],
		name: 'DEFAULT_ADMIN_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'MINTER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'PAUSER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'WHITELISTER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			},
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				internalType: 'struct OceanlandNFTBox.Category',
				name: 'category',
				type: 'tuple'
			}
		],
		name: 'addCategory',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			}
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address[]',
				name: 'accounts',
				type: 'address[]'
			},
			{
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]'
			}
		],
		name: 'balanceOfBatch',
		outputs: [
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'value',
				type: 'uint256'
			}
		],
		name: 'burn',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: 'values',
				type: 'uint256[]'
			}
		],
		name: 'burnBatch',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'buyBox',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				internalType: 'bytes',
				name: 'signature',
				type: 'bytes'
			}
		],
		name: 'buyBoxWithSignature',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			}
		],
		name: 'exists',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getCategories',
		outputs: [
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				internalType: 'struct OceanlandNFTBox.Category[]',
				name: '',
				type: 'tuple[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			}
		],
		name: 'getCategory',
		outputs: [
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				internalType: 'struct OceanlandNFTBox.Category',
				name: '',
				type: 'tuple'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getCategoryIds',
		outputs: [
			{
				internalType: 'uint256[]',
				name: '',
				type: 'uint256[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			}
		],
		name: 'getRoleAdmin',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			}
		],
		name: 'getTotalSold',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'grantRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'hasRole',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_nftContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_royaltyRecipient',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'operator',
				type: 'address'
			}
		],
		name: 'isApprovedForAll',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'mint',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: 'amounts',
				type: 'uint256[]'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'mintBatch',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'nftContract',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			}
		],
		name: 'openBox',
		outputs: [
			{
				internalType: 'uint256[]',
				name: '_nftIds',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: '_amounts',
				type: 'uint256[]'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'pause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'paused',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'renounceRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'revokeRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_tokenId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_salePrice',
				type: 'uint256'
			}
		],
		name: 'royaltyInfo',
		outputs: [
			{
				internalType: 'address',
				name: 'receiver',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'royaltyAmount',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]'
			},
			{
				internalType: 'uint256[]',
				name: 'amounts',
				type: 'uint256[]'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'safeBatchTransferFrom',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				internalType: 'bytes',
				name: 'data',
				type: 'bytes'
			}
		],
		name: 'safeTransferFrom',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'operator',
				type: 'address'
			},
			{
				internalType: 'bool',
				name: 'approved',
				type: 'bool'
			}
		],
		name: 'setApprovalForAll',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newRecipient',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'newRate',
				type: 'uint256'
			}
		],
		name: 'setRoyalties',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes4',
				name: 'interfaceId',
				type: 'bytes4'
			}
		],
		name: 'supportsInterface',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			}
		],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'unpause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'categoryId',
				type: 'uint256'
			},
			{
				components: [
					{
						internalType: 'enum OceanlandNFTBox.CategoryStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'string',
						name: 'name',
						type: 'string'
					},
					{
						internalType: 'string',
						name: 'uri',
						type: 'string'
					},
					{
						internalType: 'uint256[]',
						name: 'nftGroups',
						type: 'uint256[]'
					},
					{
						internalType: 'uint256[]',
						name: 'probabilities',
						type: 'uint256[]'
					},
					{
						internalType: 'address',
						name: 'currency',
						type: 'address'
					},
					{
						internalType: 'uint256',
						name: 'price',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limit',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'limitPerAccount',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'oneTimeBuyLimit',
						type: 'uint256'
					}
				],
				internalType: 'struct OceanlandNFTBox.Category',
				name: 'category',
				type: 'tuple'
			}
		],
		name: 'updateCategory',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256'
			}
		],
		name: 'uri',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_logic',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'admin_',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: '_data',
				type: 'bytes'
			}
		],
		stateMutability: 'payable',
		type: 'constructor'
	}
]

const contract = contracts[process.env.REACT_APP_NETWORK].nftBox

export default {
	abi,
	contract
}
